<ul class="layout-menu">
    <li>Welcome <span>{{loggedUser['firstName']}}</span></li>
    <ng-container *ngFor="let item of model; let i = index;">
        
        <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
        
    </ng-container>
    <li style="position: absolute; bottom: 1rem; padding: 0.75rem 1rem;">
        <span style="font-size:1rem;">Powered by</span>
        <a href="https://itversesolutions.in/">
        <img src="assets/layout/images/ITverse_logo_rect_trans.png" alt="Logo" class="w-3 mt-3"/>
        </a>
    </li>
    
</ul>

