import { HttpClient,HttpErrorResponse,HttpHeaders  } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, map} from 'rxjs';
import { BadInput } from '../common/errors/bad-input';
import { AppError } from '../common/errors/app-error';
import { NotFoundError } from '../common/errors/not-found-error';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
}) 
export class ApiService {
  
  apiUrl: string;
  headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});


  constructor(
    private http: HttpClient) {
      this.apiUrl = environment.apiUrl
      //console.log(this.apiUrl)
      //this.apiUrl = "http://127.0.0.1:5000/"; 
  }

  public get(uri?: string) {
    return this.http.get(this.apiUrl + uri||'', {headers: this.headers})
      .pipe(catchError(this.handleError));
  }

  public post(resource: any, uri?: string) {
    return this.http.post(this.apiUrl + uri||'', JSON.stringify(resource), {headers: this.headers} )
      .pipe(catchError(this.handleError));
  }

 

  public getFile(uri?: string) {
    console.log(uri)
    return this.http.get(this.apiUrl + uri||'', {responseType: "blob"} )
      .pipe(catchError(this.handleError));
  }

  public patch(resource: any, uri?: string) {
    return this.http.patch(this.apiUrl+ uri||'', JSON.stringify(resource))
      .pipe(catchError(this.handleError));
  }

  public delete(resource: any, uri?: string) {
    return this.http.delete(this.apiUrl + uri||'',resource)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse)  {
    if (error.status === 400)
      return throwError(()=> new BadInput(error));
    
      if (error.status === 404)
      return throwError(()=> new NotFoundError(error));

    return throwError(()=> new AppError(error));
  }
}

