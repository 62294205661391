import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthService)

  if(auth.getToken()) {
    const authReq = req.clone({
      headers: req.headers.set(
        'Authorization',
        'Bearer ' + auth.getToken()
      ),
    })

    //console.log(authReq)

    return next(authReq);
  }

  return next(req);

};
