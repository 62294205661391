import { Injectable } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  loader: BehaviorSubject<boolean>;

  constructor(
  
  ) { 
    this.loader = new BehaviorSubject(false);
  }

  getLoader() {
    return this.loader.asObservable()
  }
}
