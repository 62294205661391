import { Injectable } from '@angular/core';

//import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
  }

  
  public set(key: string, value: any) {
     window.sessionStorage.setItem(key, value);
  }

  public get(key: string) {
    return sessionStorage.getItem(key);
  }

  public remove(key: string) {
     return window.sessionStorage.removeItem(key);
  }

  public clear() {
    return window.sessionStorage.clear();
  }

  public keys() {
    return window.sessionStorage['keys'];
  }

  public length() {
    return window.sessionStorage.length;
  }
}