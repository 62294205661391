import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AuthService } from '../kirin/service/auth.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];
    loggedUser: any[];

    constructor(public layoutService: LayoutService, public auth:AuthService) { 
        this.loggedUser = this.auth.getUser()
        console.log(this.loggedUser)
    }

    ngOnInit() {
        this.model = [
            {
                items: [
                    { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'] }
                ]
            },
        ]

        switch(this.loggedUser['role']) {
            case 'SYSADMIN' : {
                this.model.push(
                    {
                        items: [
                            { label: 'Clients', icon: 'pi pi-fw pi-id-card', routerLink: ['/clients'] }
                        ]
                    },
                    {
                        items: [
                            { label: 'Invoices', icon: 'pi pi-fw pi-file', routerLink: ['/invoices'] }
                        ]
                    },
                    {
                        items: [
                            { label: 'Dockets', icon: 'pi pi-fw pi-megaphone', routerLink: ['/dockets'] }
                        ]
                    }
                )
                break;
            }
            case 'GENERAL-ADMIN' : {
                this.model.push(
                    {
                        items: [
                            { label: 'Clients', icon: 'pi pi-fw pi-id-card', routerLink: ['/clients'] }
                        ]
                    },
                    {
                        items: [
                            { label: 'Invoices', icon: 'pi pi-fw pi-file', routerLink: ['/invoices'] }
                        ]
                    },
                    {
                        items: [
                            { label: 'Dockets', icon: 'pi pi-fw pi-megaphone', routerLink: ['/dockets'] }
                        ]
                    }
                )
                break;
            }
            case 'SUPER-ADMIN' : {
                this.model.push(
                    {
                        items: [
                            { label: 'Clients', icon: 'pi pi-fw pi-id-card', routerLink: ['/clients'] }
                        ]
                    },
                    {
                        items: [
                            { label: 'Invoices', icon: 'pi pi-fw pi-file', routerLink: ['/invoices'] }
                        ]
                    },
                    {
                        items: [
                            { label: 'Dockets', icon: 'pi pi-fw pi-megaphone', routerLink: ['/dockets'] }
                        ]
                    }
                )
                break;
            }
            case 'SYSADMIN' : {
                this.model.push(
                    {
                        items: [
                            { label: 'Clients', icon: 'pi pi-fw pi-id-card', routerLink: ['/clients'] }
                        ]
                    },
                    {
                        items: [
                            { label: 'Invoices', icon: 'pi pi-fw pi-file', routerLink: ['/invoices'] }
                        ]
                    },
                    {
                        items: [
                            { label: 'Dockets', icon: 'pi pi-fw pi-megaphone', routerLink: ['/dockets'] }
                        ]
                    }
                )
                break;
            }
            case 'BILLING' : {
                this.model.push(
                    {
                        items: [
                            { label: 'Clients', icon: 'pi pi-fw pi-id-card', routerLink: ['/clients'] }
                        ]
                    },
                    {
                        items: [
                            { label: 'Invoices', icon: 'pi pi-fw pi-file', routerLink: ['/invoices'] }
                        ]
                    },
                    {
                        items: [
                            { label: 'Dockets', icon: 'pi pi-fw pi-megaphone', routerLink: ['/dockets'] }
                        ]
                    }
                )
                break;
            }
                
            case 'DOCKET-OPER' :{
                this.model.push(
                    {
                        items: [
                            { label: 'Dockets', icon: 'pi pi-fw pi-megaphone', routerLink: ['/dockets'] }
                        ]
                    }
                )
                break;
            }
            case 'DOCKET-ADMIN' :{
                this.model.push(
                    {
                        items: [
                            { label: 'Dockets', icon: 'pi pi-fw pi-megaphone', routerLink: ['/dockets'] }
                        ]
                    }
                )
                break;
            }
        }
        
    }
          
        
}
