<div class="layout-topbar">
    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>
    <a class="layout-topbar-logo" routerLink="">
        <img src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'kirin-logo-dark' : 'kirin-logo-white'}}.svg" alt="logo">
        <span>KIRIN ADVISORS</span>
    </a>
    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <button class="p-link layout-topbar-button">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </button>
        <button class="p-link layout-topbar-button" (click)="logout()">
            <i class="text-red-500 pi pi-sign-out"></i>
            <span>Logout</span>
        </button>
    </div>
</div>