import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError,finalize,throwError,timer } from 'rxjs';
import { MessageService } from 'primeng/api';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
}) 
export class ErrorInterceptor implements HttpInterceptor {

  constructor(public msgService: MessageService, public gs: GlobalService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    timer(10).subscribe(() =>this.gs.loader.next(true))
    return next.handle(req).pipe(
      catchError((error:HttpErrorResponse) => {
        // Handle the error here
        console.log(error);
        //this.gs.loader.next(false)
        this.msgService.add({ key: 'tst', severity: 'error', summary: error.statusText, detail: error.error.message });
        return throwError(()=>error);
      }),
      finalize(() => {
        //console.log('finalize')
        timer(100).subscribe(() =>this.gs.loader.next(false))
      })
    
    );
  }
}