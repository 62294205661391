import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from './kirin/service/auth.interceptor';
import { ErrorInterceptor } from './kirin/service/error.interceptor';
import { MessageService } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { GlobalService } from './kirin/service/global.service';
import "@angular/common/locales/global/en-IN";
import { LOCALE_ID } from '@angular/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';



@NgModule({
    declarations: [AppComponent, NotfoundComponent],
    imports: [
        CommonModule,
        AppRoutingModule, 
        AppLayoutModule,
        MessageModule,
        ToastModule,
        ProgressSpinnerModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        MessageService,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: LOCALE_ID, useValue: "en-IN" },
        provideHttpClient(withInterceptors([authInterceptor])),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
            deps:[MessageService, GlobalService]
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
