import { Component, OnInit } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Router } from '@angular/router';
import { AuthService } from './kirin/service/auth.service';
import { GlobalService } from './kirin/service/global.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    loggedUser: any = false;
    loading:Observable<boolean>;

    constructor(
        private primengConfig: PrimeNGConfig,
        private router: Router,
        private auth: AuthService,
        private gs: GlobalService
    ) {}

    ngOnInit() {
        this.loading = this.gs.getLoader()
        this.primengConfig.ripple = true;
        this.auth.userData.subscribe((data) => {
            this.loggedUser = data;
            if (!this.loggedUser)
                this.router.navigateByUrl('/auth/login', { replaceUrl: true });
        });
    }

    public logout(): void {
        //console.log('loggedout');
        this.auth.logout();
    }
}
