import { Component } from '@angular/core'; 
import { Router, RouterModule } from '@angular/router';

@Component({
    selector: 'app-notfound',
    templateUrl: './notfound.component.html',
})
export class NotfoundComponent { 
    constructor(private router: Router) {
        
    }
}