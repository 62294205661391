//import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, BehaviorSubject, switchMap, from, map } from 'rxjs';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';

const jwtHelper = new JwtHelperService();
const TOKEN_KEY = 'token';

type NewType = Router;

@Injectable({
  providedIn: 'root',
})
export class AuthService{
  public user!: Observable<any>;
  public userData = new BehaviorSubject<any>(false);

  constructor(
    private api: ApiService,
    private router: Router,
    private storage: StorageService
  ) {
    this.loadUser()
  }

  loadUser() {
    let token = this.storage.get(TOKEN_KEY)
    if(token) {
      this.userData.next(jwtHelper.decodeToken(token)['userData']||false)
    } else {
      this.userData.next(false)
    }
  }

  public login(user: any) {
    return this.api.post(user, 'auth/login').pipe(
      map((data: any) => 
        {
          this.storage.set(TOKEN_KEY,data.token)
          //console.log(jwtHelper.decodeToken(data.token)['userData'])
          this.userData.next(jwtHelper.decodeToken(data.token)['userData']||false)
        })
    );
  }

  getUser() {
    return this.userData.getValue();
  }

  public getOtp(data: any) {
    return this.api.post(data, 'auth/get-otp');
  }


  public  logout() {
    this.storage.remove(TOKEN_KEY);
    //await this.storage.remove('loggedUser');
    this.userData.next(false);
    this.router.navigateByUrl('auth/login', { replaceUrl: true });
  }

  public isLoggedIn() {
    return this.userData.asObservable();
  }

  getToken() {
    return this.storage.get(TOKEN_KEY);
  }

  /*
  async getLoggedUser() {
    var storedToken = await this.storage.get(TOKEN_KEY);
    return jwtHelper.decodeToken(storedToken).logged_user;
  }

  async isUserAdmin() {
    var storedToken = await this.storage.get(TOKEN_KEY);
    return jwtHelper.decodeToken(storedToken).logged_user.isUserAdmin;
  }
  */
}
